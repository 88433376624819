import React from 'react'
import TextInput from 'src/components/TextInput'
import './NewsletterSignup.scss'

class NewsletterSignup extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: ''
    }

    this.handleChangeInput = this.handleChangeInput.bind(this)
  }

  handleChangeInput(e) {
    this.setState({
      email: e.target.value
    })
  }

  render() {
    const { email } = this.state

    return (
      <div className="NewsletterSignup">
        <TextInput
          value={email}
          handleChange={this.handleChangeInput}
          label="mittnamn@minmail.se"
        />
        <div className="Consent">Genom att skicka in mina uppgifter godkänner jag Qreds behandling av personuppgifter.</div>
      </div>
    )
  }
}

export default NewsletterSignup
