import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import './CheckBox.scss'

const CheckBox = ({
  handleBlur,
  handleChange,
  inverse,
  label,
  large,
  name,
  text,
  value,
  ...rest
}) => {
  const classNames = cx({
    CheckBox: true,
    'CheckBox--inverse': inverse,
    'CheckBox--large': large
  })
  return (
    <div className={classNames}>
      <label>
        <input
          checked={value}
          name={name}
          onBlur={handleBlur}
          onChange={handleChange}
          type="checkbox"
          value={value}
          {...rest}
        />
        <div className="Input">
          <i className="icon icon-checked" />
        </div>
        {label}
        <div className="InputText">{text}</div>
      </label>
    </div>
  )
}

CheckBox.defaultProps = {
  handleBlur: () => {},
  handleChange: () => {},
  inverse: false,
  label: '',
  large: false,
  name: '',
  text: ''
}

CheckBox.propTypes = {
  inverse: PropTypes.bool,
  large: PropTypes.bool
}

export default CheckBox
