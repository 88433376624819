export default function(amount) {
  let currencyFormatter = null
  let currencyCode = null

  if (!currencyFormatter) {
    currencyFormatter = 'sv-SE'
  }

  if (!currencyCode) {
    currencyCode = 'SEK'
  }

  if (typeof amount !== "number") {
    return amount
  }

  var formatter = new Intl.NumberFormat(currencyFormatter, {
    style: "currency",
    currency: currencyCode,
    minimumFractionDigits: 0
  })
  return formatter.format(amount)
}
