import React from 'react'
import { connect } from 'react-redux'
import { toggleApplicationDialog } from 'src/actions'

const ApplicationDialogButton = ({ toggleApplicationDialog }) => {
  return (
    <div>
      <button type="button" className="ApplicationDialogButton" onClick={() => toggleApplicationDialog(true)}>
        Ansök nu
      </button>
    </div>
  )
}

export default connect(
  null,
  { toggleApplicationDialog }
)(ApplicationDialogButton)
