import React from 'react'
import PropTypes from 'prop-types'
import OutsideClickHandler from 'react-outside-click-handler'
import "./Dropdown.scss"

class Dropdown extends React.Component {
  static propTypes = {
    label: PropTypes.string,
    items: PropTypes.array
  }
  
  static defaultProps = {
    label: '',
    items: []
  }

  constructor(props) {
    super(props)

    this.state = {
      open: false
    }

    this.buttonRef = React.createRef();
    this.listRef = React.createRef();

    this.toggleList = this.toggleList.bind(this)
    this.updatePosition = this.updatePosition.bind(this)
    this.onBlur = this.onBlur.bind(this)
  }

  onBlur(e) {
    const { open } = this.state

    if (open) {
      this.setState({ open: false })
    }
  }

  toggleList(e) {
    const { open } = this.state

    this.setState({ open: !open })

    if (!open) {
      this.updatePosition()
    }
  }

  updatePosition() {
    const el = this.listRef.current

    el.style.bottom = null
    el.style.top = null

    const rect = el.getBoundingClientRect()
    const isVisible = rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)

    if (isVisible) {
      el.style.top = '100%'
    } else {
      el.style.bottom = '100%'
    }
  }

  render() {
    const { label, items } = this.props
    const { open } = this.state
    const openclassName = (open) ? 'DropdownOpen' : ''

    const listItems = items.map((item) => (
      <div className="ListItem" key={item.title}>
        <a href={item.link}>{ item.title }</a>
      </div>
    ))
  
    return (
      <div className={`Dropdown ${openclassName}`}>
        <OutsideClickHandler onOutsideClick={this.onBlur}>
          <div className="DropdownButton" ref={this.buttonRef} onClick={this.toggleList}>{ label } <i className="icon icon-dropdown" /></div>
          <div className="DropdownList" ref={this.listRef}>
            {listItems}
          </div>
        </OutsideClickHandler>
      </div>
    )
  }
}

export default Dropdown
