import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Header from 'src/components/Header'
import Footer from 'src/components/Footer'
import ApplicationDialog from 'src/components/ApplicationDialog'
import ApplicationDialogButton from 'src/components/ApplicationDialogButton'

export default ({ children, data }) => {
  if (!data || !data.site) {
    return null
  }

  const {
    site: {
      siteMetadata
    },
    currentPage,
    footerMenu,
    footerNavLeft,
    footerNavRight,
    primaryMenu
  } = data
  const { yoast_wpseo_title, yoast_wpseo_metadesc } = currentPage.yoast_meta
  const metaInfo = {
    title: yoast_wpseo_title || siteMetadata.title,
    description: yoast_wpseo_metadesc || siteMetadata.description
  }

  return (
    <>
      <Helmet>
        <title>{ metaInfo.title }</title>
        <meta name="description" content={metaInfo.description} />
        <body className={currentPage.slug} />
      </Helmet>
      <Header menu={primaryMenu} />
      <ApplicationDialog />
      <ApplicationDialogButton />
      {children}
      <Footer menu={footerMenu} navLeft={footerNavLeft} navRight={footerNavRight} />
    </>
  )
}

export const query = graphql`
  fragment Menus on Query {
    primaryMenu: wordpressMenusMenus(location: { eq: "primary" }, language: { eq: $language }) {
      items {
        title,
        url,
        slug,
        type,
        icon {
          icon,
          position
        }
      }
    },
    footerMenu: wordpressMenusMenus(location: { eq: "footer" }, language: { eq: $language }) {
      items {
        title,
        url,
        slug,
        type,
        icon {
          icon,
          position
        }
      }
    },
    footerNavLeft: wordpressMenusMenus(location: { eq: "footer-nav-left" }, language: { eq: $language }) {
      items {
        title,
        url,
        slug,
        type,
        icon {
          icon,
          position
        }
      }
    },
    footerNavRight: wordpressMenusMenus(location: { eq: "footer-nav-right" }, language: { eq: $language }) {
      items {
        title,
        url,
        slug,
        type,
        icon {
          icon,
          position
        }
      }
    }
  }
`
