import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import cx from 'classnames'

import './Button.scss'

const Button = ({
  children,
  color,
  flat,
  link,
  nav,
  round,
  size,
  to,
  type,
  ...rest
}) => {
  let Component = "button"
  const props = {}

  if (nav) {
    Component = Link
    props.to = to
  }

  if (link) {
    Component = "a"
  }

  if (!nav && !link) {
    props.type = type
  }

  const classNames = {
    Button: true,
    'Button--round': round,
    'Button--flat': flat
  }

  return (
    <Component
      className={cx(classNames)}
      data-color={color}
      data-size={size}
      {...props}
      {...rest}
    >
      {children}
    </Component>
  )
}

Button.defaultProps = {
  color: "black",
  flat: false,
  nav: false,
  link: false,
  round: false,
  to: ''
}

Button.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'white', 'black']),
  flat: PropTypes.bool,
  link: PropTypes.bool,
  nav: PropTypes.bool,
  round: PropTypes.bool,
  to: PropTypes.string
}

export default Button
