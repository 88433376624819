import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Formik } from 'formik'
import cx from 'classnames'
import axios from 'axios'
import { navigate } from 'gatsby'
import Cookies from 'js-cookie'
import debounce from 'lodash-es/debounce'
import TextInput from 'components/TextInput'
import CheckBox from 'components/CheckBox'
import Button from 'components/Button'
import InputSlider from 'components/InputSlider'
import Grid from 'components/Grid'
import currencyFormatter from 'utils/currencyFormatter'
import { toggleApplicationDialog } from 'src/actions'

import './ApplicationDialog.scss'

class ApplicationDialog extends Component {
  state = {
    companyName: '',
    termsConfirmed: false,
    sourcePage: 'SOURCE_WP_QRED'
  }

  componentDidMount() {
    let sessionId = Cookies.get('sessionid')
    if (!sessionId) {
      sessionId = Math.random()
      Cookies.set('sessionid', sessionId, { expires: 1 })
    }

    this.setState({
      googleTrafficCookie: Cookies.get('_ga'),
      landingPageUrl: document.URL,
      landingPageIdentifier: document.querySelector('body').classList.value,
      referenceUrl: document.referrer,
      webSessionID: sessionId
    })
  }

  checkOrgNumber = debounce(async (companyNo) => {
    this.setState({
      companyName: ''
    })

    if (companyNo.length === 10 ||companyNo.length === 11) {
      const resp = await axios.get(`https://se.qred.com/api/wp/search?pame="${companyNo}`)
      if (resp.data.length > 0) {
        this.setState({
          companyName: resp.data[0]
        })
      }
    }
  })

  async mapToOldApi(values) {
    const {
      landingPageUrl,
      referenceUrl,
      webSessionID
    } = this.state
    const apiToken = "HZe96PUDdnBWjybjTxtDW5f2gDe27MevgHsbeLvm"
    const customHeaders = {
      "Content-Type": "application/x-www-form-urlencoded",
      "AuthorizationToken": apiToken
    }
    const data = {
      'loan-amount': values.amount,
      'your-orgno': values.orgNumber,
      'your-email': values.email,
      'your-tel': values.tel,
      'your-consent': values.terms,
      'abtep': 'reg1',
      'abebug': 'val',
      'abource': 'qred',
      'refPar': referenceUrl,
      'phid': webSessionID,
      'lpu': landingPageUrl
    }
    let clientRegistrationEndpoint = ''
    if (window.locale === 'fi') {
      clientRegistrationEndpoint = process.env.APP_API_URL_FI
    } else if (window.locale === 'dk') {
      clientRegistrationEndpoint = process.env.APP_API_URL_DK
    } else if (window.locale === 'nl') {
      clientRegistrationEndpoint = process.env.APP_API_URL_NL
    }

    const resp = await axios.post(clientRegistrationEndpoint, data, { headers: customHeaders })
    if (resp && resp.data && !resp.data.success) {
      const fieldMap = {
        'your-tel': 'tel',
        'your-orgno': 'orgNumber',
        'your-email': 'email'
      }
      const errors = []
      resp.data.errors.forEach(error => {
        errors.push({ field: fieldMap[error.field], message:  error.message })
      })
      const ApiError = new Error()
      ApiError.errors = errors
      throw ApiError
    }

    const isReturningClient = resp.data.redi.includes("rlc")

    return [isReturningClient]
  }

  async submitNewApi(values) {
    const {
      companyName,
      googleTrafficCookie,
      landingPageIdentifier,
      landingPageUrl,
      referenceUrl,
      sourcePage,
      webSessionID
    } = this.state
    const apiToken = "HZe96PUDdnBWjybjTxtDW5f2gDe27MevgHsbeLvm"
    const customHeaders = {
      "Content-Type": "application/json;charset=utf-8",
      "AuthorizationToken": apiToken
    }
    const clientRegistrationEndpoint = 'https://test.qred.com/webapi/services/web/client-registration'
    const data = {
      amount: values.amount,
      companyName: companyName,
      companyNo: values.orgNumber,
      email: values.email,
      phone: values.tel,
      googleTrafficeCookie: googleTrafficCookie,
      landingPageIdentifier,
      landingPageUrl,
      referenceUrl,
      sourcePage,
      webSessionID
    }
    const resp = await axios.post(clientRegistrationEndpoint, data, { headers: customHeaders })
    if (resp && resp.data && Array.isArray(resp.data.errorFields)) {
      const fieldMap = {
        amount: 'amount',
        companyNo: 'orgNumber',
        email: 'email',
        phone: 'tel'
      }
      const errors = []
      resp.data.errorFields.forEach(error => {
        errors.push({ field: fieldMap[error.field], message:  error.message })
      })
      const ApiError = new Error()
      ApiError.errors = errors
      throw ApiError
    }

    // Submit form data to hubspot
    await axios.post('/se/hubspot/forms/loan-application', {
      email: values.email,
      phone: values.tel,
      company: companyName,
      market: window.locale,
      pageName: document.title,
      customerType: resp.data.returningClient ? 'rlc' : 'nlc'
    })

    const isReturningClient = resp.data.returningClient

    return [isReturningClient]
  }

  handleTermsConsent = (e) => {
    this.setState({
      termsConfirmed: e.target.checked
    })
  }

  handleFormSubmit = async (values, actions) => {
    try {
      let isReturningClient = false
      if (['nl', 'fi', 'dk'].includes(window.locale)) {
        [isReturningClient] = await this.submitOldApi(values)
      } else {
        [isReturningClient] = await this.submitNewApi(values)
      }

      // Set orgnumber cookie for Bank account gateway integration
      Cookies.set('orgnumber', values.orgNumber, { expires: 7 })

      this.props.toggleApplicationDialog(false)

      // The application 1st step is complete
      let to = '/'
      if (isReturningClient) {
        window.dataLayer.push({
          'event'         :   'rlcApplicationComplete',
          'eventCategory' :   'RLC Application Complete'

        })
        to = this.$t('links.returningLoanCustomers.url')
      } else {
        window.dataLayer.push({
          'event'         :   'nlcApplicationComplete',
          'eventCategory' :   'NLC Application Complete'
        })
        to = this.$t('links.newLoanCustomers.url')
      }
      navigate(`/${window.locale}/${to}`)
    } catch (apiErrors) {
      if (Array.isArray(apiErrors.errors)) {
        apiErrors.errors.forEach(error => actions.setFieldError(error.field, error.message))
      }
    }

    actions.setSubmitting(false)
  }

  handleFormValidation = (values) => {
    let errors = {}

    if (!values.terms) {
      errors.terms = 'Vänligen bekräfta att du godkänner villkoren'
    }

    return errors
  }

  renderForm = (props) => {
    const { companyName } = this.state

    return (
      <form className="ApplicationDialogForm" onSubmit={props.handleSubmit}>
        <Grid item sm={8} md={6}>
          <InputSlider
            formatter={(amount) => currencyFormatter(amount)}
            handleChange={amount => props.setValues({ amount })}
            initialValue={props.values.amount}
            inverse
            label="Önskat belopp"
            max={500000}
            min={10000}
            step={10000}
          />
        </Grid>
        <Grid item sm={8} md={6}>
          <TextInput
            filled={props.dirty.orgNumber}
            handleChange={e => {
              this.checkOrgNumber(e.target.value)
              props.handleChange(e)
            }}
            inverse
            label="Företagets org.nr"
            name="orgNumber"
            onBlur={props.handleBlur}
            // required
            text={companyName || props.errors.orgNumber}
            type="text"
            value={props.values.orgNumber}
          />
        </Grid>
        <Grid item sm={8} md={6}>
          <TextInput
            filled={props.dirty.tel}
            onBlur={props.handleBlur}
            handleChange={props.handleChange}
            inverse
            label="Mobil"
            name="tel"
            // required
            text={props.errors.tel}
            type="tel"
            value={props.values.tel}
          />
        </Grid>
        <Grid item sm={8} md={6}>
          <TextInput
            filled={props.dirty.email}
            onBlur={props.handleBlur}
            handleChange={props.handleChange}
            inverse
            label="E-post"
            name="email"
            // required
            text={props.errors.email}
            type="email"
            value={props.values.email}
          />
        </Grid>
        <Grid item sm={8} md={6}>
          <CheckBox
            inverse
            name="terms"
            handleBlur={props.handleBlur}
            handleChange={props.handleChange}
            label="Jag förstår att Qred sparar och behandlar mina personuppgifter för att kunna göra en korrekt kreditbedömning och för att uppfylla gällande lagar och förordningar."
            text={props.touched.terms && props.errors.terms}
            type="checkbox"
          />
        </Grid>
        <Button
          color="white"
          flat
          round
          type="submit"
        >
          Ansök
        </Button>
      </form>
    )
  }

  render() {
    const { showDialog, toggleApplicationDialog } = this.props
    const initialValues = {
      amount: 50000,
      orgNumber: '',
      tel: '',
      email: '',
      terms: false
    }

    return (
      <div className={cx('ApplicationDialog', { 'ApplicationDialog--active': showDialog })}>
        <div className="ApplicationDialogHeader">
          <a href={'https://www.qred.com'}>
            <div className="LogoDesktop" />
          </a>
          <button type="button" className="CloseBtn" onClick={() => toggleApplicationDialog(false)}>
            <i className="icon-close" />
          </button>
        </div>
        <div className="ApplicationDialogContent">
          <Grid container justify="center">
            <Grid item xs={8}>
              <div>
                <h2>Ansök om företagslån</h2>
                <h3>Det är gratis att ansöka och inte bindande.</h3>
                <Formik
                  initialValues={initialValues}
                  onSubmit={this.handleFormSubmit}
                  render={this.renderForm}
                  validate={this.handleFormValidation}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    )
  }
}

export default connect(
  (state) => {
    return {
      showDialog: state.applicationDialog.open
    }
  },
  { toggleApplicationDialog }
)(ApplicationDialog)
