import React from 'react'
import { Link as GatsbyLink } from 'gatsby'

const Link = ({ children, icon, slug, title, to, type, url, ...rest }) => {
  let Component = null
  const props = {
    ...rest
  }

  if (type === 'custom') {
    Component = 'a'
    props.href = url
  } else {
    Component = GatsbyLink
    props.to = slug || to
  }

  return (
    <Component {...props}>
      {icon && icon.position === 'before' && (
        <i className={`icon ${icon.icon}`} />
      )}
      {children || title}
      {icon && icon.position === 'after' && (
        <i className={`icon ${icon.icon}`} />
      )}
    </Component>
  )
}

export default Link
