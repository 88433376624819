import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import './Grid.scss'

const Grid = ({
  align,
  children,
  container,
  item,
  justify,
  lg,
  md,
  row,
  sm,
  xl,
  xs
}) => {
  if (!container && !row && !item) {
    console.warn('Grid element must be one of: container, row, item!')
  }

  const classNames = {
    Grid: true,
    'Grid--container': container,
    'Grid--row': row,
    'Grid--item': item,
    'Grid--align-start': align === 'start',
    'Grid--align-center': align === 'center',
    'Grid--align-end': align === 'end',
    'Grid--justify-start': justify === 'start',
    'Grid--justify-center': justify === 'center',
    'Grid--justify-end': justify === 'end',
    [`Grid--xs${xs}`]: xs,
    [`Grid--sm${sm}`]: sm,
    [`Grid--md${md}`]: md,
    [`Grid--lg${lg}`]: lg,
    [`Grid--xl${xl}`]: xl,
  }

  return (
    <div className={cx(classNames)}>
      {children}
    </div>
  )
}

Grid.defaultProps = {
  container: false,
  item: false,
  row: false
}

Grid.propTypes = {
  align: PropTypes.oneOf(['start', 'center', 'end']),
  container: PropTypes.bool,
  item: PropTypes.bool,
  justify: PropTypes.oneOf(['start', 'center', 'end']),
  lg: PropTypes.number,
  md: PropTypes.number,
  row: PropTypes.bool,
  sm: PropTypes.number,
  xl: PropTypes.number,
  xs: PropTypes.number
}

export default Grid
