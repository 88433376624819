import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Slider, Rail, Handles, Tracks } from 'react-compound-slider'

import './InputSlider.scss'

const InputSlider = ({
  formatter,
  handleChange,
  initialValue,
  inverse,
  label,
  max,
  min,
  name,
  step,
}) => {
  const classNames = cx({
    InputSlider: true,
    'InputSlider--inverse': inverse
  })
  const [value, setValue] = useState(initialValue)

  return (
    <div className={classNames}>
      <div className="InputSliderInput">
        <input type="text" name={name} readOnly value={formatter(initialValue)} />
        <label>{label}</label>
      </div>
      <Slider
        mode={1}
        step={step}
        domain={[min, max]}
        onUpdate={(values) => {
          setValue(values[0])
          handleChange(values[0])
        }}
        values={[value]}
        className="InputSliderTrack"
      >
        <Rail>
          {({ getRailProps }) => (
            <div className="InputSliderTrack-rail" {...getRailProps()} />
          )}
        </Rail>
        <Handles>
          {({ handles, getHandleProps }) => (
            <>
              {handles.map(({ id, value, percent }) => (
                <div
                  key={id}
                  role="slider"
                  aria-valuemin={min}
                  aria-valuemax={max}
                  aria-valuenow={value}
                  className="InputSliderThumb"
                  style={{
                    left: `calc(${percent}% - 15px)`,
                  }}
                  {...getHandleProps(id)}
                />
              ))}
            </>
          )}
        </Handles>
        <Tracks right={false}>
          {({ tracks, getTrackProps }) => (
            <>
              {tracks.map(({ id, source, target }) => (
                <div
                  key={id}
                  className="InputSliderTrack-fill"
                  style={{
                    width: `${target.percent - source.percent}%`
                  }}
                  {...getTrackProps()}
                />
              ))}
            </>
          )}
        </Tracks>
      </Slider>
    </div>
  )
}

InputSlider.defaultProps = {
  formatter: (value) => value,
  handleChange: () => {},
  initialValue: 0,
  inverse: false,
  label: '',
  max: 100,
  min: 1,
  name: '',
  step: 1
}

InputSlider.propTypes = {
  formatter: PropTypes.func,
  handleChange: PropTypes.func,
  initialValue: PropTypes.number,
  inverse: PropTypes.bool,
  label: PropTypes.string.isRequired,
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  step: PropTypes.number.isRequired
}

export default InputSlider
