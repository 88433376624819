import {
  APPLICATION_DIALOG_TOGGLE
} from 'src/actions'

const initialState = {
  open: false
}

const state = (state = initialState, action) => {
  switch (action.type) {
    case APPLICATION_DIALOG_TOGGLE:
      const status = typeof action.status !== 'undefined'
        ? action.status
        : !state.open

      document.querySelector('body').classList.toggle('disable-scroll', status)

      return {
        ...state,
        open: status
      }
    default:
      return state
  }
}

export default state
