import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import './TextInput.scss'

class TextInput extends React.Component {
  static propTypes = {
    handleChange: PropTypes.func,
    inverse: PropTypes.bool,
    label: PropTypes.string,
    large: PropTypes.bool,
    name: PropTypes.string,
    required: PropTypes.bool,
    text: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
  }

  static defaultProps = {
    handleBlur: () => {},
    handleChange: () => {},
    label: '',
    large: false,
    name: '',
    required: false,
    inverse: false,
    text: '',
    type: 'text',
    value: ''
  }

  render() {
    const {
      handleBlur,
      handleChange,
      inverse,
      label,
      large,
      name,
      required,
      text,
      type,
      value
    } = this.props
    const filled = ("" + value).length > 0
    const classNames = cx({
      TextInput: true,
      'TextInput--inverse': inverse,
      'TextInput--large': large
    })

    return (
      <div className={classNames}>
        <input
          data-filled={filled}
          name={name}
          onBlur={handleBlur}
          onChange={handleChange}
          required={required}
          type={type}
          value={value}
        />
        <label>{ label }</label>
        <div className="InputText">{text}</div>
      </div>
    )
  }
}

export default TextInput
