// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-page-js": () => import("/Users/p/Work/gatsby-cms-frontend/src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---node-modules-gatsby-plugin-s-3-fake-index-js": () => import("/Users/p/Work/gatsby-cms-frontend/node_modules/gatsby-plugin-s3/fake-index.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-s-3-fake-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/p/Work/gatsby-cms-frontend/.cache/data.json")

