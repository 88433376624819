import React from 'react'
import { connect } from 'react-redux/es'
import { toggleApplicationDialog } from 'src/actions'
import Link from 'src/components/Link'
import Button from 'src/components/Button'
import './Header.scss'

const Header = ({ menu, toggleApplicationDialog }) => (
  <header>
    <div className="HeaderWrapper">
      <Link to="/">
        <div className="LogoDesktop" />
      </Link>
      <div className="HeaderLinks">
        {menu && Array.isArray(menu.items) && (
          <>
            {menu.items.map((item, i) => <Link {...item} className="HeaderLink" key={i} />)}
            <Button round flat onClick={() => toggleApplicationDialog(true)}>Ansök</Button>
          </>
        )}
      </div>
    </div>
    <div className="MobileHeaderWrapper">
      <Link to="/">
        <div className="LogoMobile" />
      </Link>
      <div className="PageTitle"></div>
      <i className="icon-user" />
    </div>
  </header>
)

export default connect(
  null,
  { toggleApplicationDialog }
)(Header)
