import React from 'react'
import Link from 'src/components/Link'
import NewsletterSignup from 'src/components/NewsletterSignup'
import Dropdown from 'src/components/Dropdown'
import './Footer.scss'

const Footer = ({ menu, navLeft, navRight }) => {
  const languageLinks = [
    {
      title: "Sweden",
      link: "/se"
    },
    {
      title: "Finland",
      link: "/fi"
    },
    {
      title: "Denmark",
      link: "/dk"
    },
    {
      title: "The Netherlands",
      link: "/nl"
    }
  ]
  return (
    <div className="SiteFooter">
      <div className="Wrapper">
        <div className="Nav">
          <div className="NavColumn">
            <h5>Meny</h5>
            <ul>
              {navLeft && Array.isArray(navLeft.items) &&
                navLeft.items.map((item, i) => <li key={i}><Link {...item} /></li> )
              }
            </ul>
          </div>
          <div className="NavColumn">
            <h5>Mer om Qred</h5>
            <ul>
              {navRight && Array.isArray(navRight.items) &&
                navRight.items.map((item, i) => <li key={i}><Link {...item} /></li> )
              }
            </ul>
          </div>
          <div className="NavColumn">
            <h5>Kontakta oss</h5>
            <address className="Contact">
              <a href="https://maps.google.com/?q=Qred Företagslån AB, Tulegatan 15, 113 53 Stockholm">Qred Företagslån AB<br/>
              559008-9800<br/>
              Tulegatan 15<br/>
              113 53 Stockholm</a><br/>
              <br/>
              <a href="tel:020150333"><i className="icon icon-phone" /> 020 - 150 333</a><br/>
              <a href="mailto:support@qred.se"><i className="icon icon-mail" /> support@qred.se</a>
            </address>
          </div>
          <div className="NavColumn">
            <h5>Prenumerera på nyhetsbrev</h5>
            <NewsletterSignup />
          </div>
        </div>
        <div className="Bottom">
          <div className="Links">
            <Dropdown label="Välj land" items={languageLinks} />
            {menu && Array.isArray(menu.items) &&
              menu.items.map((item, i) => <Link {...item} key={i} />)
            }
          </div>
          <div className="Social">
            <a href="https://www.facebook.com/qredsverige" target="_blank" rel="noopener noreferrer"><i className="icon icon-facebook" /></a>
            <a href="https://www.linkedin.com/company/qred-ab" target="_blank" rel="noopener noreferrer"><i className="icon icon-linkedin" /></a>
            <a href="https://www.instagram.com/qredsverige/" target="_blank" rel="noopener noreferrer"><i className="icon icon-instagram" /></a>
            <a href="https://twitter.com/qredsverige" target="_blank" rel="noopener noreferrer"><i className="icon icon-twitter" /></a>
            <a href="https://www.youtube.com/channel/UCfchsLbzEoDULZUUoRCThqw" target="_blank" rel="noopener noreferrer"><i className="icon icon-youtube" /></a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
